import { get, sortBy } from 'lodash';

export function isHistoryChanged(inventory) {
  const firstPublishedDate = get(inventory, 'firstPublishedDate', 0);
  let history = get(inventory, `computedDisplayInfo.priceHistoryInfo.historicalPrices`, []);
  const displayPrice = get(inventory, `prices.displayPrice`, 0);

  history = sortBy(history, 'timestamp').filter(({ timestamp }) => timestamp >= firstPublishedDate);

  return !history.every(price => get(price, 'amount') === displayPrice);
}
