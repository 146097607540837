import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { MAKE, MODEL } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { UsurpSelectMakeModelFilter } from 'site-modules/shared/components/inventory/usurp-filter/usurp-select-filter/usurp-select-make-model-filter';

export function UsurpMultiSelectFilters({
  facetData,
  facetType,
  onUpdate,
  currentMakeName,
  currentMake,
  currentModel,
}) {
  const selectProps = {
    onUpdate,
    facetType,
    facet: facetData,
  };

  return (
    <Fragment>
      {facetType === MAKE && (
        <>
          <label className="d-block small text-capitalize mb-0_25" htmlFor="usurp-make-select">
            {facetType}
          </label>
          <UsurpSelectMakeModelFilter {...selectProps} currentValue={currentMake} />
        </>
      )}
      {facetType === MODEL && currentMake && (
        <>
          <label className="d-block small text-capitalize mb-0_25" htmlFor="usurp-model-select">
            {facetType}
          </label>
          <UsurpSelectMakeModelFilter {...selectProps} make={currentMakeName} currentValue={currentModel} />
        </>
      )}
    </Fragment>
  );
}

UsurpMultiSelectFilters.propTypes = {
  facetData: PropTypes.shape({
    config: PropTypes.shape({}),
  }),
  facetType: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  currentMakeName: PropTypes.string,
  currentMake: PropTypes.string,
  currentModel: PropTypes.string,
};

UsurpMultiSelectFilters.defaultProps = {
  facetData: {},
  selectedFacets: {},
  currentMakeName: '',
  currentMake: '',
  currentModel: '',
};
