import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { FACETS_CONTROLS } from 'site-modules/shared/constants/inventory/facets-config';
import { FacetsConfigShape } from 'site-modules/shared/constants/inventory/filters-constants';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { UsurpSwitchFilter } from 'site-modules/shared/components/usurp-switch-filter/usurp-switch-filter';
import { UsurpFilterLegend } from 'site-modules/shared/components/inventory/usurp-filter/usurp-filter-legend/usurp-filter-legend';
import { UsurpFilterCheckboxFactory } from './usurp-filter-checkbox/usurp-filter-checkbox-factory';
import { UsurpRadiusSlider } from './usurp-radius-slider/usurp-radius-slider';
import { UsurpRangeFilter } from './usurp-range-filter/usurp-range-filter';
import { UsurpMultiSelectFilters } from './usurp-multi-select-filters/usurp-multi-select-filters';

import './usurp-filter.scss';

export class UsurpFilter extends Component {
  static propTypes = {
    facet: PropTypes.shape({
      config: PropTypes.shape({}),
    }),
    className: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
    facetsConfig: FacetsConfigShape.isRequired,
    withTitle: PropTypes.bool,
    selectedFacets: PropTypes.shape({}),
    isElectricRangeFacetDisplayed: PropTypes.bool,
    currentMMConfig: PropTypes.shape({
      currentMakeName: PropTypes.string,
      currentMake: PropTypes.string,
      currentModel: PropTypes.string,
    }),
    modelFacetValues: PropTypes.shape({}),
    isNestedFilter: PropTypes.bool,
    searchResultsFound: PropTypes.number,
  };

  static defaultProps = {
    facet: {},
    className: null,
    withTitle: true,
    selectedFacets: {},
    isElectricRangeFacetDisplayed: false,
    currentMMConfig: {},
    modelFacetValues: {},
    isNestedFilter: false,
    searchResultsFound: undefined,
  };

  getFilterComponent = facet => {
    const { selectedFacets, currentMMConfig, modelFacetValues, facetsConfig, searchResultsFound } = this.props;
    const { values = [], min, max, type, config, minValue, maxValue } = facet;
    const { control, step, allowEqualValues } = config;
    const key = `${type}-${values.length}`;

    switch (control) {
      case FACETS_CONTROLS.CHECKBOX: {
        return (
          <UsurpFilterCheckboxFactory
            onUpdate={this.handleUpdate}
            facet={facet}
            modelFacetValues={modelFacetValues}
            facetsConfig={facetsConfig}
          />
        );
      }
      case FACETS_CONTROLS.RADIUS_SLIDER: {
        const preselectedValue = values.findIndex(({ selected }) => selected);

        return (
          <UsurpRadiusSlider
            onUpdate={this.handleUpdate}
            facet={facet}
            preselectedLeftInd={preselectedValue}
            key={`${key}-${preselectedValue}`}
          />
        );
      }
      case FACETS_CONTROLS.RANGE: {
        const rangeMin = minValue || +get(values, [0, 'name'], 0);
        // Sometimes we get "1000+" as an option name which can't be directly converted to a number
        const rangeMax = maxValue || Number(get(values, [values.length - 1, 'name'], 0).replace('+', ''));
        const preselectedLeft = min || rangeMin;
        const preselectedRight = max || rangeMax;
        const isRangeDisabled = rangeMin === rangeMax || (!allowEqualValues && rangeMin + step === rangeMax);

        return (
          <UsurpRangeFilter
            onUpdate={this.handleUpdate}
            facet={facet}
            min={rangeMin}
            max={rangeMax}
            preselectedLeft={+preselectedLeft}
            preselectedRight={+preselectedRight}
            hasTwoThumbs
            key={`${key}-${preselectedLeft}-${preselectedRight}`}
            searchResultsFound={searchResultsFound}
            disabled={isRangeDisabled}
          />
        );
      }
      case FACETS_CONTROLS.SWITCH: {
        const { copy } = config;
        return <UsurpSwitchFilter copy={copy} onUpdate={this.handleUpdate} selectedFacets={selectedFacets} />;
      }
      case FACETS_CONTROLS.SELECT: {
        return (
          <UsurpMultiSelectFilters
            facetData={facet}
            facetType={type}
            onUpdate={this.handleUpdate}
            selectedFacets={selectedFacets}
            {...currentMMConfig}
          />
        );
      }
      default:
        return null;
    }
  };

  handleUpdate = (selectedFacet, isSelected) => {
    const { facet, onUpdate } = this.props;
    const type = selectedFacet.type || facet.type;
    onUpdate(type, selectedFacet, isSelected);
  };

  render() {
    const { facet, className, withTitle, isElectricRangeFacetDisplayed, isNestedFilter } = this.props;
    const { config, values = [], lowerBound } = facet;

    if (!config) {
      return null;
    }

    const { creativeId, title, alternativeTitle, appendZeroOption, subtitle = {} } = config;

    let updatedFacet;
    if (!lowerBound && appendZeroOption) {
      updatedFacet = { ...facet, values: [{ name: '0' }, ...values] };
    }

    const resultedFacet = updatedFacet || facet;
    const displayedTitle = isElectricRangeFacetDisplayed && alternativeTitle ? alternativeTitle : title;
    const { subtitleText, subtitleClassNames, subtitleTooltip } = subtitle;
    const tooltipTitle = get(subtitleTooltip, 'title', '');
    const tooltipText = get(subtitleTooltip, 'text', '');
    const tooltipId = get(subtitleTooltip, 'id', '');
    const hasTooltip = !!tooltipText;
    const subtitleEl = !!subtitleText && (
      <p className={classnames('size-12 text-cool-gray-40', subtitleClassNames)}>
        {subtitleText}
        {hasTooltip && (
          <TooltipItem
            id={tooltipId}
            item={{
              text: (
                <Fragment>
                  {!!tooltipTitle && <b className="d-block">{tooltipTitle}</b>}
                  {tooltipText}
                </Fragment>
              ),
              placement: 'auto',
            }}
            iconClassName="text-primary"
            screenReaderTooltipLabel={displayedTitle}
          />
        )}
      </p>
    );

    return (
      <div
        className={classnames('usurp-filter', className, {
          'bg-white px-1 py-1 usurp-filter-nested': isNestedFilter,
        })}
        data-tracking-parent={creativeId}
      >
        {isNestedFilter ? (
          <fieldset>
            <UsurpFilterLegend
              config={config}
              withTitle={withTitle}
              isElectricRangeFacetDisplayed={isElectricRangeFacetDisplayed}
            />
            {subtitleEl}
            {this.getFilterComponent(resultedFacet)}
          </fieldset>
        ) : (
          <>
            {subtitleEl}
            {this.getFilterComponent(resultedFacet)}
          </>
        )}
      </div>
    );
  }
}
