import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, debounce } from 'lodash';
import {
  getFormattedUsurpLabel,
  fireUpdateTrackingPixel,
} from 'site-modules/shared/utils/inventory-utils/range-facet-utils';
import { Range } from 'site-modules/shared/components/inventory/range/range';

export class UsurpRadiusSlider extends Component {
  static propTypes = {
    facet: PropTypes.shape({}).isRequired,
    onUpdate: PropTypes.func.isRequired,
    preselectedLeftInd: PropTypes.number,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    hasTwoThumbs: false,
    preselectedLeftInd: 0,
    preselectedRightInd: 0,
    disabled: false,
  };

  state = {
    selectedLeftInd: this.props.preselectedLeftInd,
  };

  getOptions = values =>
    values.map((opt, ind) => (ind % 2 === 0 ? `${opt.value}${get(this.props, 'facet.config.labelPostfix', '')}` : ''));

  handleChange = newLeftValue => {
    if (isFinite(newLeftValue)) {
      this.setState({ selectedLeftInd: newLeftValue });
    }
  };

  handleUpdate = () => {
    const { selectedLeftInd } = this.state;
    const { onUpdate, facet, preselectedLeftInd, disabled } = this.props;
    const { values, type } = facet;

    const selectedFacet = values[selectedLeftInd];

    if (selectedFacet && preselectedLeftInd !== selectedLeftInd && !disabled) {
      const { value, name } = selectedFacet;
      const creativeId = get(facet, 'config.creativeId', '');

      fireUpdateTrackingPixel({ type, value, name, creativeId });

      onUpdate(selectedFacet, true);
    }
  };

  debouncedUpdate = debounce(this.handleUpdate, 300);

  render() {
    const { selectedLeftInd } = this.state;
    const { facet, disabled } = this.props;
    const { values, config, type } = facet;
    const { title, label } = config;
    const name = title || label;
    const options = this.getOptions(values);

    const selectedName = get(values, [selectedLeftInd, 'name'], '0');

    return (
      <Range
        min={0}
        max={values.length - 1}
        selectedMin={selectedLeftInd}
        name={name}
        minOutputText={getFormattedUsurpLabel({ facetType: type, name: selectedName })}
        onMinChange={this.handleChange}
        handleUpdate={this.debouncedUpdate}
        step={1}
        options={options}
        disabled={disabled}
        label={
          <span className="d-flex w-100 justify-content-between">
            <span>Search radius</span>
            <span className="text-blue-30 font-weight-bold">{selectedName}</span>
          </span>
        }
      />
    );
  }
}
