import { useEffect, useState } from 'react';
import { get } from 'lodash';

export const useSectionScrollable = (ref, dependency) => {
  const [scrollable, setScrollable] = useState(false);

  useEffect(() => {
    if (ref && ref.current) {
      setScrollable(get(ref.current, 'scrollHeight') > get(ref.current, 'clientHeight'));
    }
  }, [ref, dependency]);

  return scrollable;
};
