import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
/* Constants */
import { TrackingConstant } from 'client/tracking/constant';
import { ACTIONS } from 'client/engagement-handlers/inventory-engagement-handler/constants';
/* Utils */
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingHandler } from 'client/tracking/handler';
/* Components */
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';

import './delivery-toggle.scss';

const OPTIONS = [
  'This will include known delivery fees into the prices you see when shopping cars for sale, so you can compare the true cost to you.',
  "This will exclude any vehicles that don't offer delivery to your area.",
  'Please note that Edmunds price ratings do not take delivery fees into account, even when included in the price.',
];

export function DeliveryToggle({ toggleDeliveryFees, isMobile, includeDeliveryFee }) {
  const [pressed, setPressed] = useState(includeDeliveryFee);

  useEffect(() => {
    setPressed(includeDeliveryFee);
  }, [includeDeliveryFee]);

  const onDeliveryFeesTooltipOpen = () => {
    TrackingHandler.fireEvent(TrackingConstant.ACTION_VIEW_CONTENT, {
      event_data: {
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        creative_id: 'edm-entry-facets-buying-experience',
        value: 'Including Delivery Fees',
      },
    });
  };
  const toggle = () => {
    setPressed(!pressed);
    toggleDeliveryFees(!pressed);

    EventToolbox.fireCustomEvent(ACTIONS.TOGGLE_DELIVERY_FEES, {
      isIncluded: !pressed,
    });
  };

  const deliveryFeesTooltip = (
    <div className="px-1">
      <div className="size-16 font-weight-bold my-1">Including Delivery Fees</div>
      <ul className="px-0 pl-1">
        {OPTIONS.map((option, index) => (
          <li key={`option_${index + 1}`} className="medium mb-0_5">
            {option}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div
      className={classNames('delivery-toggle-wrapper mt-1_5 d-flex', {
        checked: pressed,
        'justify-content-center': isMobile,
      })}
    >
      <div className="d-flex">
        <button
          aria-pressed={pressed}
          aria-labelledby="deliveryToggle"
          className="slider round pos-r"
          style={{
            cursor: 'pointer',

            width: '44px',
            minWidth: '44px',
            height: '24px',
            backgroundColor: '#fff',
            transition: '0.2s',
            border: '1px solid #ccc',
            borderRadius: '100px',
            ...(pressed && { backgroundColor: '#0069bf' }),
          }}
          onClick={toggle}
          data-testid="toggle-btn"
        />
        <div className="pl-0_75">
          <span id="deliveryToggle" className="font-weight-medium">
            Include Delivery Fees in Prices
          </span>
          <div className="d-inline-block">
            <TooltipItem
              id="delivery-sees-tooltip"
              className="top-0 middle align-middle"
              item={{
                text: deliveryFeesTooltip,
                placement: 'bottom',
              }}
              onTooltipOpen={onDeliveryFeesTooltipOpen}
              screenReaderTooltipLabel="including delivery fees"
              data-testid="tooltip"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DeliveryToggle.propTypes = {
  toggleDeliveryFees: PropTypes.func,
  isMobile: PropTypes.bool,
  includeDeliveryFee: PropTypes.bool,
};

DeliveryToggle.defaultProps = {
  toggleDeliveryFees: noop,
  isMobile: false,
  includeDeliveryFee: false,
};
