import * as API from 'site-modules/shared/constants/allowed-inventory-request-params';
import { buildCombinedRangeLabel } from 'site-modules/shared/utils/inventory-utils/range-facet-utils';
import { facetsConfig } from 'site-modules/shared/constants/inventory/facets-config';

export function buildFacetValueNiceName(facet) {
  const { type, group = '', name, max, min } = facet;

  const groupValueNiceNameBuilders = {
    [API.MODEL]: () => `${group} ${name}`,
    [API.EDITORIAL_RATING]: () => `${name} Rating`,
  };

  if (type in groupValueNiceNameBuilders) {
    return {
      ...facet,
      niceName: groupValueNiceNameBuilders[type](),
    };
  }

  return max || min
    ? {
        ...facet,
        name: buildCombinedRangeLabel(min, max, facetsConfig[type]),
        niceName: buildCombinedRangeLabel(min, max, facetsConfig[type]),
      }
    : facet;
}
