import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, constant } from 'lodash';
import classnames from 'classnames';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import Button from 'reactstrap/lib/Button';
import { getFacetTrackingParams, getSelectedFacetTrackingParams } from 'site-modules/shared/utils/inventory/tracking';
import { buildFilterPixelValue } from 'site-modules/shared/utils/inventory-utils/build-filter-pixel-value';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { DEAL_TYPE, INVENTORY_TYPE } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { FacetsConfigShape, facetShape } from 'site-modules/shared/constants/inventory/filters-constants';
import { DEAL_TYPES, DEAL_TYPE_ICON, TEXT_COLOR } from 'site-modules/shared/components/deal/deal-constants';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';

import './checkbox-facet.scss';

export function DefaultCheckbox({ selected, selectedClassName, deselectedClassName }) {
  return (
    <span
      className={classnames('checkbox checkbox-icon size-18', selected ? selectedClassName : deselectedClassName)}
    />
  );
}

DefaultCheckbox.propTypes = {
  selected: PropTypes.bool,
  selectedClassName: PropTypes.string,
  deselectedClassName: PropTypes.string,
};

DefaultCheckbox.defaultProps = {
  selected: false,
  selectedClassName: 'icon-checkbox-checked3 text-primary-darker',
  deselectedClassName: 'icon-checkbox-unchecked3 text-cool-gray-50',
};

export class CheckboxFacet extends Component {
  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
    facet: facetShape,
    facetsConfig: FacetsConfigShape.isRequired,
    hideCount: PropTypes.bool,
    type: PropTypes.string.isRequired,
    encodePixelValue: PropTypes.bool,
    countClasses: PropTypes.string,
    innerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  };

  static defaultProps = {
    facet: {},
    hideCount: false,
    encodePixelValue: false,
    countClasses: 'ml-0_25',
    innerRef: undefined,
  };

  handleClick = e => {
    const { onUpdate, facet } = this.props;
    e.preventDefault();
    onUpdate(facet, !facet.selected);
  };

  isFacetDisabled = () => {
    const { facet, type } = this.props;
    const { count = 0, selected } = facet;
    return type === INVENTORY_TYPE && count === 0 && !selected;
  };

  renderTooltipTarget = (targetProps, ref) => (
    <Button {...targetProps} ref={ref} className="border-0 background-none p-0">
      <i className="icon-info text-gray-darker small" aria-hidden />
    </Button>
  );

  render() {
    const { facet, hideCount, type, encodePixelValue, facetsConfig, countClasses, innerRef } = this.props;
    const { name = '', count = 0, selected, rgb, value } = facet;
    const getPixelDisplayName = get(facetsConfig, [type, 'getPixelDisplayName'], constant(name));
    const dataTrackingValue = buildFilterPixelValue(type, value || name, getPixelDisplayName(facet), {
      encodePixelValue,
    });
    const trackingData = selected
      ? getSelectedFacetTrackingParams(dataTrackingValue)
      : getFacetTrackingParams(dataTrackingValue);
    const isColorCheckbox = !!rgb;
    const isDealTypeCheckbox = type === DEAL_TYPE;
    const isFacetDisabled = this.isFacetDisabled();
    const getValueName = get(facetsConfig, [type, 'getValueName'], constant(name));
    const getValueTooltip = get(facetsConfig, [type, 'getValueTooltip']);

    return (
      <Label
        className={classnames('checkbox-facet d-flex align-items-center mb-0 justify-content-between', {
          'text-gray no-pointer': isFacetDisabled,
        })}
        {...isColorCheckbox && { title: name }}
        {...trackingData}
      >
        <span className="d-inline-flex align-items-center">
          <Input
            onChange={this.handleClick}
            type="checkbox"
            className="input m-0 no-focus"
            checked={!!selected}
            disabled={isFacetDisabled}
            innerRef={innerRef}
          />
          <DefaultCheckbox selected={selected} />
          <span className="name ml-0_5" title={name}>
            {isDealTypeCheckbox && DEAL_TYPES.includes(value) && (
              <i
                className={classnames(DEAL_TYPE_ICON[value], TEXT_COLOR[value])}
                style={{ display: 'inline-block', width: '13px', marginRight: '0.75rem' }}
                aria-hidden
              />
            )}
            {isColorCheckbox && (
              <span
                style={{
                  backgroundColor: `rgb(${rgb})`,
                  display: 'inline-block',
                  marginRight: '0.25rem',
                  width: '1rem',
                  height: '1rem',
                  borderWidth: '1px',
                  verticalAlign: 'text-top',
                }}
                className="color-checkbox rounded-circle"
              />
            )}
            {getValueName(facet)}
            {!!getValueTooltip && (
              <TooltipItem
                id={makeNiceName(name)}
                item={{
                  text: <Fragment>{getValueTooltip(facet)}</Fragment>,
                  placement: 'auto',
                }}
                tooltipContainerTag="span"
                screenReaderTooltipLabel={name}
                renderTarget={this.renderTooltipTarget}
              />
            )}
          </span>
        </span>
        {!hideCount && (
          <span className={classnames(countClasses, 'text-cool-gray-40')}>({numberWithCommas(count)})</span>
        )}
      </Label>
    );
  }
}
