import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'site-modules/shared/components/link/link';
import { ToggleButtonBase } from 'site-modules/shared/components/inventory/toggle-button-base/toggle-button-base';

export const ToggleButton = props => {
  const { tag, isActive } = props;
  let ariaProps;

  if (tag === Link) {
    ariaProps = isActive ? { 'aria-current': 'page' } : {};
  } else {
    ariaProps = { 'aria-pressed': isActive };
  }

  return <ToggleButtonBase {...props} {...ariaProps} />;
};

ToggleButton.propTypes = {
  tag: PropTypes.elementType,
  isActive: PropTypes.bool,
};

ToggleButton.defaultProps = {
  isActive: false,
  tag: undefined,
};
