import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InventoryEntities } from 'client/data/models/inventory';
import { getPlural } from 'client/utils/plural';
import { CollapsibleFiltersGroup } from 'site-modules/shared/components/inventory/collapsible-filters-group/collapsible-filters-group';
import { transformSelectedFacetsForDisplaying } from 'site-modules/shared/utils/inventory/search-filter';
import { Badge } from 'site-modules/shared/components/inventory/badge/badge';
import { SuggestedFacets } from 'site-modules/shared/components/inventory/suggested-facets/suggested-facets';
import { useSectionScrollable } from 'site-modules/shared/hooks/use-section-scrollable';
import { buildFacetValueNiceName } from 'site-modules/shared/utils/inventory/build-facet-value-nice-name';

import './active-filters-group.scss';

const MAX_SHOWN_ACTIVE_FILTERS_COUNT = 9;

export function ActiveFiltersGroup({ facets, onUpdate }) {
  const [overflowY, setOverflowY] = useState('hidden');
  const filtersRowRef = useRef();
  const scrollable = useSectionScrollable(filtersRowRef, facets);

  const addScroll = useCallback(isOpen => {
    setOverflowY(isOpen ? 'auto' : 'hidden');
  }, []);

  const filters = transformSelectedFacetsForDisplaying(facets).map(facet => buildFacetValueNiceName(facet));
  const activeFiltersCount = filters.length;
  const btnFilterText = (
    <>
      Applied Filters
      {!!activeFiltersCount && (
        <Badge className="ml-0_25 align-middle">
          {activeFiltersCount > MAX_SHOWN_ACTIVE_FILTERS_COUNT
            ? `${MAX_SHOWN_ACTIVE_FILTERS_COUNT}+`
            : activeFiltersCount}
          <span className="sr-only">Active</span>
        </Badge>
      )}
    </>
  );
  const trackingValue = `${filters.length} Active ${getPlural('Filter', filters.length)}`;

  return (
    filters.length > 0 && (
      <CollapsibleFiltersGroup
        textClosed={btnFilterText}
        textOpen={btnFilterText}
        btnContainerClassName="p-0"
        trackingValue={trackingValue}
        accessibilityCallback={addScroll}
        isOpenOnLoad
      >
        <button
          onClick={onUpdate}
          type="button"
          className="clear-all-btn text-cool-gray-30 medium p-0 mx-1 mt-0_25 text-decoration-none"
        >
          <i className="icon icon-cross2 size-12 mr-0_25" aria-hidden />
          <span>clear all</span>
          <span className="sr-only"> active filters</span>
        </button>
        <div
          className={classnames('active-filters d-flex flex-wrap pt-0_5 mt-0_5 px-0 px-md-1 pb-0_5', { scrollable })}
          ref={filtersRowRef}
          style={{ overflowY }}
        >
          <SuggestedFacets
            suggestedFacets={filters}
            onSuggestedFacetClick={onUpdate}
            wrapperClassnames="flex-wrap"
            itemClassnames="mb-0_5 active-filters-item"
            shouldWrap={false}
          />
        </div>
      </CollapsibleFiltersGroup>
    )
  );
}

ActiveFiltersGroup.propTypes = {
  facets: InventoryEntities.Facets,
  onUpdate: PropTypes.func.isRequired,
};

ActiveFiltersGroup.defaultProps = {
  facets: [],
};
