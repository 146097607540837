import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Histogram } from 'site-modules/shared/components/inventory/histogram/histogram';
import { last, isUndefined, compact } from 'lodash';
import {
  DISPLAY_PRICE,
  LEASE_PAYMENT,
  LOAN_PAYMENT,
} from 'site-modules/shared/constants/allowed-inventory-request-params';
import { LoadingDots } from 'site-modules/shared/components/loading-dots/loading-dots';

const parseInterval = interval => {
  const [key, value] = interval;

  const [min, max] = key.split('-');

  return {
    min: Number(min) || 0,
    max: Number(max) || Number.MAX_VALUE,
    value,
    name: key,
  };
};

export const getIntervalsForPaymentType = (intervalsData, facetType) => {
  const keyFacetTypeMap = {
    [DISPLAY_PRICE]: DISPLAY_PRICE,
    [LOAN_PAYMENT]: 'loan.payment',
    [LEASE_PAYMENT]: 'estimateLeasePromise.monthlyPayment',
  };

  return intervalsData?.[`prices.${keyFacetTypeMap[facetType]}`];
};

const transformIntervalsToMap = intervalsData =>
  compact(
    intervalsData?.map(interval => {
      const key = Object.keys(interval)[0];

      if (!key) {
        return null;
      }

      return [key, interval[key]];
    }, {})
  );

const sumOpenedIntervalWithLast = intervalsEntries => {
  if (intervalsEntries.length <= 1) {
    return intervalsEntries;
  }

  const latestClosedInterval = intervalsEntries[intervalsEntries.length - 2];
  const [, openedIntervalValue] = last(intervalsEntries);

  return [...intervalsEntries.slice(0, -2), [latestClosedInterval[0], latestClosedInterval[1] + openedIntervalValue]];
};

const DEFAULT_DATA = [];

export const PricingHistogram = memo(props => {
  const { intervals, min, max, queryForByIntervalAggregation, onUpdateQuery } = props;

  useEffect(() => {
    if (queryForByIntervalAggregation) {
      onUpdateQuery(queryForByIntervalAggregation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryForByIntervalAggregation]);

  const data = useMemo(() => {
    if (!intervals) {
      return DEFAULT_DATA;
    }
    return sumOpenedIntervalWithLast(transformIntervalsToMap(intervals)).map(interval => parseInterval(interval));
  }, [intervals]);

  return (
    <div className="d-flex flex-column w-100">
      <div className="pos-r d-flex">
        <Histogram activeRangeMin={min} activeRangeMax={max} data={data} />
        {isUndefined(intervals) && (
          <div
            className="pos-a d-flex justify-content-center align-center w-100"
            style={{ top: 0, bottom: 0, left: 0 }}
          >
            <LoadingDots />
          </div>
        )}
      </div>
    </div>
  );
});

PricingHistogram.displayName = 'PricingHistogram';
PricingHistogram.propTypes = {
  facetType: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  intervals: PropTypes.arrayOf(PropTypes.shape({})),
  queryForByIntervalAggregation: PropTypes.string,
};
PricingHistogram.defaultProps = {
  intervals: undefined,
  queryForByIntervalAggregation: undefined,
};
