import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { VALUE_INPUTS_NAMES } from 'site-modules/shared/components/range/range';
import { Input } from 'site-modules/shared/components/inventory/input/input';

import './usurp-range-inputs-panel.scss';

export function UsurpRangeInputsPanel(props) {
  const {
    hasTwoThumbs,
    maxValue,
    minValue,
    inputAddon,
    labelAddon,
    showLabels,
    disableInputs,
    minOutputText,
    onInputBlur,
    onInputChange,
    onInputFocus,
    onInputKeyDown,
    rangeName,
    className,
  } = props;

  return (
    <div className={classnames('d-flex align-items-center w-100', className)}>
      <div className="usurp-range-inputs-panel-input-wrapper">
        {hasTwoThumbs && showLabels && (
          <label htmlFor={`${VALUE_INPUTS_NAMES.MIN}-${rangeName}`} className="small mb-0_25">
            {`${labelAddon}Min`}
          </label>
        )}
        <Input
          id={`${VALUE_INPUTS_NAMES.MIN}-${rangeName}`}
          startAdornment={inputAddon}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          value={minValue}
          onChange={onInputChange}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          onKeyDown={onInputKeyDown}
          disabled={disableInputs}
          name={VALUE_INPUTS_NAMES.MIN}
          aria-label={hasTwoThumbs ? `Min ${rangeName} value` : minOutputText}
        />
      </div>
      {hasTwoThumbs && (
        <Fragment>
          {showLabels && <span className="small mx-1 mt-1_25">to</span>}
          <div className="usurp-range-inputs-panel-input-wrapper">
            {hasTwoThumbs && showLabels && (
              <label htmlFor={`${VALUE_INPUTS_NAMES.MAX}-${rangeName}`} className="small mb-0_25">
                {`${labelAddon}Max`}
              </label>
            )}
            <Input
              id={`${VALUE_INPUTS_NAMES.MAX}-${rangeName}`}
              startAdornment={inputAddon}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              value={maxValue}
              onChange={onInputChange}
              onBlur={onInputBlur}
              onFocus={onInputFocus}
              onKeyDown={onInputKeyDown}
              disabled={disableInputs}
              name={VALUE_INPUTS_NAMES.MAX}
              aria-label={`Max ${rangeName} value`}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

UsurpRangeInputsPanel.propTypes = {
  minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hasTwoThumbs: PropTypes.bool,
  showLabels: PropTypes.bool,
  labelAddon: PropTypes.string,
  inputAddon: PropTypes.string,
  disableInputs: PropTypes.bool,
  minOutputText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rangeName: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  onInputKeyDown: PropTypes.func.isRequired,
  className: PropTypes.string,
};

UsurpRangeInputsPanel.defaultProps = {
  hasTwoThumbs: false,
  showLabels: false,
  labelAddon: '',
  inputAddon: null,
  disableInputs: false,
  minOutputText: undefined,
  className: '',
};
