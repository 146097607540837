import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { DrawerV2 } from 'site-modules/shared/components/drawer/drawer-v2';
import { UsurpFiltersDrawerContentAsync } from './usurp-filters-drawer-content-async';

export class UsurpFiltersDrawer extends PureComponent {
  static propTypes = {
    onApply: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    drawerContentComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    dataTrackingParent: PropTypes.string,
    drawerContainerClasses: PropTypes.string,
    drawerContentClasses: PropTypes.string,
    isMobile: PropTypes.bool,
    drawerContainerSelector: PropTypes.string,
    onDestroy: PropTypes.func,
  };

  static defaultProps = {
    isOpen: false,
    drawerContentComponent: UsurpFiltersDrawerContentAsync,
    dataTrackingParent: undefined,
    drawerContainerClasses: '',
    drawerContentClasses: '',
    isMobile: false,
    drawerContainerSelector: undefined,
    onDestroy: noop,
  };

  render() {
    const {
      isOpen,
      onApply,
      onToggle,
      drawerContentComponent,
      dataTrackingParent,
      drawerContainerClasses,
      drawerContentClasses,
      isMobile,
      drawerContainerSelector,
      onDestroy,
      ...props
    } = this.props;

    const DrawerContent = drawerContentComponent;
    return (
      <DrawerV2
        isOpen={isOpen}
        toggle={onApply}
        side={isMobile ? 'bottom' : 'right'}
        size={isMobile ? 'small' : 'medium'}
        classNames={{
          drawerContainer: classnames('usurp-filters-drawer h-100', drawerContainerClasses),
          drawerContent: drawerContentClasses,
        }}
        data-tracking-parent={dataTrackingParent}
        drawerContainerSelector={drawerContainerSelector}
        onDestroy={onDestroy}
      >
        <DrawerContent onDrawerClose={onToggle} onApply={onApply} isMobile={isMobile} {...props} />
      </DrawerV2>
    );
  }
}
