import React from 'react';
import PropTypes from 'prop-types';
import { TRIM, EDITORIAL_RATING } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { UsurpEditorialRatingFilter } from 'site-modules/shared/components/inventory/usurp-editorial-rating-filter/usurp-editorial-rating-filter';
import { UsurpTrimFilterCheckbox } from 'site-modules/shared/components/inventory/usurp-filter/usurp-filter-checkbox/usurp-trim-filter-checkbox';
import { UsurpFilterCheckbox } from 'site-modules/shared/components/inventory/usurp-filter/usurp-filter-checkbox/usurp-filter-checkbox';
import { facetShape } from 'site-modules/shared/constants/inventory/filters-constants';

export function UsurpFilterCheckboxFactory(props) {
  const { facet } = props;

  if (facet.type === TRIM) {
    return <UsurpTrimFilterCheckbox {...props} />;
  }

  if (facet.type === EDITORIAL_RATING) {
    return <UsurpEditorialRatingFilter {...props} />;
  }

  return <UsurpFilterCheckbox {...props} />;
}

UsurpFilterCheckboxFactory.propTypes = {
  facet: facetShape,
  onUpdate: PropTypes.func.isRequired,
};

UsurpFilterCheckboxFactory.defaultProps = {
  facet: {},
};
