import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { UsurpFilter } from 'site-modules/shared/components/inventory/usurp-filter/usurp-filter';

export function UsurpFiltersGroup({
  facets,
  selectedFacets,
  onUpdate,
  withInnerDividers,
  isElectricRangeFacetDisplayed,
  modelFacetValues,
  facetsConfig,
}) {
  return (
    <Fragment>
      {facets.map((facet, index) => {
        const isLast = index === facets.length - 1;
        return (
          <Fragment key={facet.type || facet.group}>
            <UsurpFilter
              facet={facet}
              onUpdate={onUpdate}
              className={isLast ? '' : 'mb-1'}
              selectedFacets={selectedFacets}
              isElectricRangeFacetDisplayed={isElectricRangeFacetDisplayed}
              modelFacetValues={modelFacetValues}
              isNestedFilter={withInnerDividers}
              facetsConfig={facetsConfig}
              withTitle
            />
          </Fragment>
        );
      })}
    </Fragment>
  );
}

UsurpFiltersGroup.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  facetsConfig: PropTypes.shape({}).isRequired,
  facets: PropTypes.arrayOf(PropTypes.shape({})),
  withInnerDividers: PropTypes.bool,
  selectedFacets: PropTypes.shape({}),
  isElectricRangeFacetDisplayed: PropTypes.bool,
  modelFacetValues: PropTypes.shape({}),
};

UsurpFiltersGroup.defaultProps = {
  facets: [],
  withInnerDividers: false,
  selectedFacets: {},
  isElectricRangeFacetDisplayed: false,
  modelFacetValues: {},
};
