import React from 'react';
import PropTypes from 'prop-types';
import { CollapsibleFiltersGroup } from 'site-modules/shared/components/inventory/collapsible-filters-group/collapsible-filters-group';
import { FiltersGroupTitle } from 'site-modules/shared/components/inventory/usurp-filters-group/filters-group-title';

import './filters-group-wrapper.scss';

export function FiltersGroupWrapper({
  title,
  isSrOnlyTitle,
  creativeId,
  children,
  withCollapse,
  hideCollapseSeparator,
  adornmentGroupConfig,
  tooltip,
  legendConfig,
}) {
  const AdornmentGroupComponent = adornmentGroupConfig?.component;
  const adornmentProps = adornmentGroupConfig?.props || {};

  if (!children) return null;

  if (withCollapse) {
    return (
      <CollapsibleFiltersGroup
        textClosed={
          <FiltersGroupTitle
            wrapperTag="span"
            titleTag="span"
            baseTitleClassNames="text-wrap"
            baseTooltipClassNames="filters-group-wrapper-title pos-r"
            title={title}
            isSrOnlyTitle={isSrOnlyTitle}
            tooltip={tooltip}
            legendConfig={legendConfig}
          />
        }
        textOpen={
          <FiltersGroupTitle
            wrapperTag="span"
            titleTag="span"
            baseTitleClassNames="text-wrap"
            baseTooltipClassNames="filters-group-wrapper-title pos-r"
            title={title}
            isSrOnlyTitle={isSrOnlyTitle}
            tooltip={tooltip}
            legendConfig={legendConfig}
          />
        }
        hideCollapseSeparator={hideCollapseSeparator}
        trackingId="view_filter_section"
        collapseTrackingId="close_filter_section"
        trackingValue={title}
        btnContainerClassName="px-0_5"
        collapseClassName="px-1 pb-1"
        creativeId={creativeId}
      >
        {AdornmentGroupComponent && <AdornmentGroupComponent {...adornmentProps} />}
        <fieldset>
          <legend className="sr-only">{title}</legend>
          {children}
        </fieldset>
      </CollapsibleFiltersGroup>
    );
  }

  return (
    <div className="filters-group-wrapper p-1" {...(creativeId ? { 'data-tracking-parent': creativeId } : {})}>
      <fieldset>
        <FiltersGroupTitle
          title={title}
          baseTitleClassNames="mb-0 heading-5 font-weight-bold"
          isSrOnlyTitle={isSrOnlyTitle}
          tooltip={tooltip}
          legendConfig={{
            ...legendConfig,
            legendClassNames: 'mb-1',
          }}
        />
        {AdornmentGroupComponent && <AdornmentGroupComponent {...adornmentProps} />}
        {children}
      </fieldset>
    </div>
  );
}

FiltersGroupWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  isSrOnlyTitle: PropTypes.bool,
  withCollapse: PropTypes.bool,
  hideCollapseSeparator: PropTypes.bool,
  creativeId: PropTypes.string,
  tooltip: PropTypes.shape({ id: PropTypes.string, text: PropTypes.string, iconClassName: PropTypes.string }),
  adornmentGroupConfig: PropTypes.shape({
    component: PropTypes.elementType,
    props: PropTypes.shape({}),
  }),
  legendConfig: PropTypes.shape({
    legendClassNames: PropTypes.string,
    titleClassNames: PropTypes.string,
  }),

  children: PropTypes.node,
};

FiltersGroupWrapper.defaultProps = {
  isSrOnlyTitle: false,
  withCollapse: false,
  hideCollapseSeparator: false,
  creativeId: null,
  tooltip: {},
  adornmentGroupConfig: null,
  legendConfig: undefined,
  children: null,
};
