import { getQuery } from 'client/utils/location';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { INVENTORY_TYPE } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import { INVENTORY_TYPES_LOWERCASE } from 'client/constants/inventory-types';

/**
 * Get correct inventory type reqeust params
 * @param {Boolean} isUsed
 * @param {Boolean} isCpo
 * @returns {Object}
 */
export const getInventoryTypeParams = (isUsed, isCpo) => {
  if (isCpo) {
    return {
      [INVENTORY_TYPE]: INVENTORY_TYPES_LOWERCASE.CPO,
    };
  }
  return {
    [INVENTORY_TYPE]: isUsed
      ? `${INVENTORY_TYPES_LOWERCASE.USED},${INVENTORY_TYPES_LOWERCASE.CPO}`
      : INVENTORY_TYPES_LOWERCASE.NEW,
  };
};

// this param used for getting a fresh browser history
export const getFreshHistoryParam = () => ({ wz: Math.floor(Math.random() * 100) });

export const buildCurrentUrlWithRandomParam = location => {
  // this is just used for getting a fresh browser history, not actually for setting the zip
  const { pathname } = location;
  const query = getQuery(location);
  return `${pathname}?${objectToQueryString({ ...query, ...getFreshHistoryParam() })}`;
};
