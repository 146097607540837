import React, { Fragment } from 'react';
import { flatten, values, map, reduce } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { UsurpFilterCheckbox } from 'site-modules/shared/components/inventory/usurp-filter/usurp-filter-checkbox/usurp-filter-checkbox';
import { facetShape } from 'site-modules/shared/constants/inventory/filters-constants';

import './usurp-trim-filter-checkbox.scss';

function sortByKey(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((res, key) => {
      res[key] = obj[key];
      return res;
    }, {});
}

function getGroupsWithMakes({ groups }) {
  return reduce(
    groups,
    (res, value, makeModelName) => {
      res[`${makeModelName.replace('|', ' ')}`] = groups[makeModelName];
      return res;
    },
    {}
  );
}

export function UsurpTrimFilterCheckbox(props) {
  const { facet, modelFacetValues, ...rest } = props;
  const { groups } = facet;
  const numberOfModels = Object.keys(groups).length;
  const numberOfMakes = Object.keys(modelFacetValues).length;
  const groupByModel = numberOfModels > 1 || numberOfMakes > 1;
  let index = 0;

  return groupByModel ? (
    map(sortByKey(getGroupsWithMakes({ groups })), (trimValues, makeModelName) => {
      const isLast = index === numberOfModels - 1;
      index += 1;

      return (
        <Fragment key={makeModelName}>
          <div
            className={classnames('bg-white px-1 py-1 usurp-trim-filter-checkbox', {
              'mb-1': !isLast,
            })}
          >
            <fieldset>
              <legend className="mb-1">
                <h4 className="text-gray-darker font-weight-medium mb-0 size-14">{makeModelName}</h4>
              </legend>
              <UsurpFilterCheckbox
                facet={{ ...facet, values: trimValues, config: { ...facet.config, maxOptionsBeforeCollapse: 3 } }}
                encodePixelValue
                {...rest}
              />
            </fieldset>
          </div>
        </Fragment>
      );
    })
  ) : (
    <UsurpFilterCheckbox facet={{ ...facet, values: flatten(values(groups)) }} encodePixelValue {...rest} />
  );
}

UsurpTrimFilterCheckbox.propTypes = {
  facet: facetShape,
  onUpdate: PropTypes.func.isRequired,
  modelFacetValues: PropTypes.shape({}),
};

UsurpTrimFilterCheckbox.defaultProps = {
  facet: {},
  modelFacetValues: {},
};
