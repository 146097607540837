import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ZIP_CODE } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { getFacetTrackingParamsWithDefaultValue } from 'site-modules/shared/utils/inventory/tracking';
import { StyledZipInput } from 'site-modules/shared/components/styled-zip-input/styled-zip-input';
import { fireTrackingZipChange } from 'site-modules/shared/utils/fire-tracking-zip-change';
import { Input } from 'site-modules/shared/components/inventory/input/input';

import './usurp-zip-input.scss';

export function UsurpZipInput({ onZipCodeUpdate, isNational, className, isMobile, dataTrackingParent }) {
  return (
    <div className="usurp-zip-input-wrapper pos-r" data-tracking-parent={dataTrackingParent || undefined}>
      <StyledZipInput
        styledWrapperClasses={classnames('usurp-zip-input ', className)}
        inputAriaLabel="Near ZIP"
        facetName={ZIP_CODE}
        onValidate={validation.validateZip}
        onZipChange={onZipCodeUpdate}
        getTracking={getFacetTrackingParamsWithDefaultValue}
        forceEmptyZip={isNational}
        placeholder={isNational ? 'ZIP' : ''}
        isMobile={isMobile}
        fireTrackingZipChange={fireTrackingZipChange}
        btnColor="primary-b"
        btnClassname="font-weight-medium btn-blue-50"
        inputComponent={Input}
        inputClasses="usurp-zip-input-control"
        inputSize=""
      />
    </div>
  );
}

UsurpZipInput.propTypes = {
  onZipCodeUpdate: PropTypes.func.isRequired,
  isNational: PropTypes.bool,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  dataTrackingParent: PropTypes.string,
};

UsurpZipInput.defaultProps = {
  isNational: false,
  className: '',
  isMobile: false,
  dataTrackingParent: 'edm-entry-facets-zip_and_radius',
};
