import { INVENTORY_TYPES } from 'client/constants/inventory-types';

/**
 * Returns counts of inventories of a given (NEW/USED/CPO) inventory type.
 */
export function getInventoryTypesCount(inventories) {
  return inventories.reduce(
    (acc, { type }) => {
      const results = acc;
      if (type === INVENTORY_TYPES.USED) {
        results.USED += 1;
      } else if (type === INVENTORY_TYPES.CPO) {
        results.CPO += 1;
      } else {
        results.NEW += 1;
      }
      return results;
    },
    {
      CPO: 0,
      NEW: 0,
      USED: 0,
    }
  );
}
