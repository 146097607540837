import { get, head } from 'lodash';
import {
  LEASE_PAYMENT,
  LOAN_PAYMENT,
  PAYMENT_TYPE,
} from 'site-modules/shared/constants/allowed-inventory-request-params';
import {
  LEASE_PAYMENT as SEO_LEASE_PAYMENT,
  LOAN_PAYMENT as SEO_LOAN_PAYMENT,
} from 'site-modules/shared/constants/allowed-seo-srp-request-params';

const PAYMENTS = {
  [SEO_LEASE_PAYMENT]: LEASE_PAYMENT,
  [SEO_LOAN_PAYMENT]: LOAN_PAYMENT,
};

/**
 * Gets payment type based on user selection.
 * @param {Object} selected

 * @returns {String | Undefined}
 */
export function getSelectedPaymentType(selected) {
  const filterPaymentLease = get(selected, LEASE_PAYMENT) && LEASE_PAYMENT;
  const filterPaymentLoan = get(selected, LOAN_PAYMENT) && LOAN_PAYMENT;
  const filterPayment = filterPaymentLease || filterPaymentLoan;
  const selectedPayment = PAYMENTS[head(get(selected, PAYMENT_TYPE))];
  return filterPayment || selectedPayment;
}

/**
 * Gets current payment type based on user selection and default value.
 * @param {Object} selected
 * @param {String} defaultPaymentType

 * @returns {String}
 */
export function getCurrentPaymentType(selected, defaultPaymentType) {
  const defaultPayment = defaultPaymentType === SEO_LEASE_PAYMENT ? LEASE_PAYMENT : LOAN_PAYMENT;

  return getSelectedPaymentType(selected) || defaultPayment;
}
