import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniq, compact } from 'lodash';
import classnames from 'classnames';
import { FACETS_CONFIG, FACETS_GROUPS_CONFIG } from 'site-modules/shared/constants/inventory/usurp-filters-config';
import { InventoryEntities } from 'client/data/models/inventory';
import { SuggestedFacet } from 'site-modules/shared/components/inventory/suggested-facet/suggested-facet';

import './suggested-facets.scss';

const FACETS_CONFIG_MAP = {
  facetsConfig: FACETS_CONFIG,
  facetsGroupsConfig: FACETS_GROUPS_CONFIG,
};

const SUGGESTED_FACETS_CREATIVE_ID = 'edm-entry-suggested-facets';

export class SuggestedFacets extends Component {
  static propTypes = {
    suggestedFacets: PropTypes.arrayOf(InventoryEntities.FacetValue),
    onSuggestedFacetClick: PropTypes.func.isRequired,
    wrapperClassnames: PropTypes.string,
    itemClassnames: PropTypes.string,
    searchId: PropTypes.string,
    creativeId: PropTypes.string,
    shouldWrap: PropTypes.bool,
    withoutRemoval: PropTypes.bool,
  };

  static defaultProps = {
    suggestedFacets: [],
    wrapperClassnames: 'flex-nowrap',
    itemClassnames: '',
    searchId: undefined,
    shouldWrap: true,
    withoutRemoval: undefined,
    creativeId: SUGGESTED_FACETS_CREATIVE_ID,
  };

  onFacetClick = (type, filter, isChecked) => {
    if (filter && filter.name) {
      if (isChecked) {
        this.selectionOrder.push(filter.name);
      } else {
        this.selectionOrder.filter(selectedFacetName => selectedFacetName !== filter.name);
      }
    }
    this.props.onSuggestedFacetClick(type, filter, isChecked);
  };

  selectionOrder = [];

  render() {
    const {
      suggestedFacets,
      wrapperClassnames,
      itemClassnames,
      searchId,
      shouldWrap,
      creativeId,
      withoutRemoval,
    } = this.props;

    const selectedFacets = uniq(
      compact(
        this.selectionOrder
          .map(selectedFacetName =>
            suggestedFacets.find(facet => facet && facet.name === selectedFacetName && !!facet.selected)
          )
          // facets that were selected on page load
          .concat(suggestedFacets.filter(facet => !!facet.selected))
      )
    );
    const restFacets = suggestedFacets.filter(facet => !facet.selected);
    const facets = selectedFacets.concat(restFacets);

    return (
      <ul
        className={classnames('suggested-facets list-unstyled d-flex mb-0', wrapperClassnames)}
        data-tracking-parent={creativeId}
      >
        {facets.map(filter => (
          <li
            key={`${filter.type}-${filter.name}`}
            className={classnames('mr-0_5', itemClassnames, `filter-${filter.type}`)}
          >
            <SuggestedFacet
              filter={filter}
              onClick={this.onFacetClick}
              searchId={searchId}
              shouldWrap={shouldWrap}
              facetsConfigMap={FACETS_CONFIG_MAP}
              withoutRemoval={withoutRemoval}
            />
          </li>
        ))}
      </ul>
    );
  }
}
