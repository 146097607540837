import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'site-modules/shared/components/inventory/histogram/bar';

const VIEW_BOX = {
  height: 130,
  width: 326,
};
const BAR_COLOR = '#4E91F5';
const INACTIVE_BAR_COLOR = '#CBD4E1';

export const Histogram = memo(props => {
  const { gutter, barColor, data, inactiveBarColor, activeRangeMin, activeRangeMax } = props;
  const svgRef = useRef();

  const barWidth = data.length && (VIEW_BOX.width - (data.length - 1) * gutter) / data.length;

  const bars = data.map((item, index) => {
    const isActiveBar = activeRangeMin < item.max && activeRangeMax >= item.min;

    return (
      <Bar
        key={index} // eslint-disable-line react/no-array-index-key
        index={index}
        barColor={isActiveBar ? barColor : inactiveBarColor}
        gutter={gutter}
        barWidth={barWidth}
        maxDataValue={Math.max(...data.map(dataItem => dataItem.value))}
        value={item.value}
        viewBox={VIEW_BOX}
      />
    );
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      width="100%"
      viewBox={`0 0 ${VIEW_BOX.width} ${VIEW_BOX.height}`}
    >
      {bars}
    </svg>
  );
});

Histogram.displayName = 'Histogram';
Histogram.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      min: PropTypes.number,
      max: PropTypes.number,
    })
  ).isRequired,
  activeRangeMax: PropTypes.number,
  activeRangeMin: PropTypes.number,
  barColor: PropTypes.string,
  inactiveBarColor: PropTypes.string,
  gutter: PropTypes.number,
};
Histogram.defaultProps = {
  barColor: BAR_COLOR,
  inactiveBarColor: INACTIVE_BAR_COLOR,
  gutter: 2,
  activeRangeMax: Number.MAX_VALUE,
  activeRangeMin: 0,
};
