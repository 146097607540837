import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EDMUNDS_LOGO_NO_TEXT } from 'client/images/inline-svgs/edmunds-logo';

export function EdmundsTested({ className }) {
  return (
    <span
      className={classnames(
        'bg-cool-gray-80 font-weight-medium text-cool-gray-30 text-nowrap rounded-6 size-12 px-0_5 py-0_25 d-inline-block',
        className
      )}
      style={{ letterSpacing: '1px' }}
    >
      {EDMUNDS_LOGO_NO_TEXT({ fill: '#2070E8', width: '18px', classes: 'mr-0_25' })}
      edmunds
      <span className="ml-0_25 font-weight-bold" style={{ letterSpacing: '3px' }}>
        TESTED
      </span>
    </span>
  );
}

EdmundsTested.propTypes = { className: PropTypes.string };
EdmundsTested.defaultProps = { className: '' };
