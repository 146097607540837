/* eslint-disable camelcase */
import { forIn, get, isNumber } from 'lodash';

import { EventToolbox } from 'client/utils/event-toolbox';
import { getCreativeId, getInvTreatmentList } from 'client/engagement-handlers/helper';
import { fireListTracking } from 'client/engagement-handlers/list-engagement-handler/list-engagement-handler';
import { getInventoryTypesCount } from 'site-modules/shared/utils/inventory-utils/get-inventory-types-count';
import { getPriceDelta } from 'site-modules/shared/components/profile/utils/extract-helper';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { TrackingConstant } from 'client/tracking/constant';
import { getCorrectStatus } from 'site-modules/shared/components/inventory/helpers/vehicle-history-helper';
import { randomInt } from 'client/utils/seed-randomizers';
import { INVENTORY_PAGE_CUSTOM_EVENTS } from 'client/site-modules/shared/constants/inventory/page-events';
import { isDealerPriceAvailable } from 'client/site-modules/shared/utils/inventory-utils/is-dealer-price-available';
import { isHistoryChanged } from 'client/site-modules/shared/utils/inventory-utils/is-history-changed';
import {
  ACTIONS,
  DATA_PATH,
  DEFAULT_DELIVERY_CODE,
  DEFAULT_INVENTORY_COUNT,
  DEFAULT_VALUE,
  MATCH_RESULT,
  NEGATIVE,
  PHOTO_TYPE,
  POSITIVE,
  VIN_OVERVIEW_ID,
} from './constants';

/**
 * Returns passed true/false values based on passed statement.
 * @param statement
 * @param truePath
 * @param falsePath
 * @returns {*}
 */
function verifyStatement(statement, truePath, falsePath) {
  return statement ? truePath : falsePath;
}

/**
 * Check if provided value is a number and rounds it if true.
 * @param value
 * @param defaultValue
 * @returns {number|string}
 */
function getValueToFixed(value, defaultValue) {
  return isNumber(value) ? value.toFixed(1) : defaultValue;
}

/**
 * Returns inventory list tracking data.
 * @param {object[]} inventories
 * @param {object} [defaults]
 * @param {object} [attributes]
 * @param {number} totalCount
 * @param {boolean} [isSrp]
 * @param {object} [photo360]
 * @param {boolean} [useTotalPriceChange]
 * @param {boolean} [isNational]
 * @param {boolean} [radius]
 * @param {boolean} useDealerPrice
 * @returns {object}
 */
export function getInventoryListTrackingData({
  inventories,
  totalCount = DEFAULT_INVENTORY_COUNT,
  defaults = {},
  attributes,
  isSrp,
  photo360,
  useTotalPriceChange,
  isNational,
  radius,
  useDealerPrice,
}) {
  const cpo_results = getInventoryTypesCount(inventories).CPO;
  const new_results = getInventoryTypesCount(inventories).NEW;
  const used_results = getInventoryTypesCount(inventories).USED;
  const match_results = inventories.length;
  const match_total = totalCount || match_results;
  const price_drop = [];
  const total_price_change = [];
  const photo_type = [];
  const dealer_rating = [];
  const price = [];
  const deal_indicator = [];
  const below_market = [];
  const days_listed = [];
  const recommended_offer = [];
  const five_star_dealer = [];
  const vehicle_history = [];
  const match_type_list = [];
  const miles_away = [];

  forIn(inventories, inventory => {
    if (!inventory.isSoldVin) {
      const priceDrop = getPriceDelta(inventory.prices, inventory);
      const roundedPriceDrop = priceDrop && formatPriceString(priceDrop, 0, 2);
      const dealerPhotos = get(inventory, DATA_PATH.VDP_DEALER_PHOTO) || get(inventory, DATA_PATH.SRP_DEALER_PHOTO);
      let deliveryCode;
      const inventoryRadius = Math.round(get(inventory, 'dealerInfo.distance', 0));

      if (isNational || radius < inventoryRadius) {
        deliveryCode = get(inventory, DATA_PATH.DELIVERY_CODE, DEFAULT_DELIVERY_CODE);
      } else {
        deliveryCode = DEFAULT_DELIVERY_CODE;
      }

      match_type_list.push(MATCH_RESULT.DELIVERY[deliveryCode]);
      if (useTotalPriceChange) {
        if (!isHistoryChanged(inventory)) {
          total_price_change.push('');
        } else {
          total_price_change.push(get(inventory, 'computedDisplayInfo.priceHistoryInfo.totalPriceChange', ''));
        }
      } else {
        price_drop.push(verifyStatement(priceDrop, roundedPriceDrop, DEFAULT_VALUE));
      }

      if (photo360) {
        if (get(inventory, `vehicleInfo.photo.photo360.${photo360.provider}.hasExterior`)) {
          photo_type.push(PHOTO_TYPE.CAR_EXTERIOR);
        }

        if (get(inventory, `vehicleInfo.photo.photo360.${photo360.provider}.hasInterior`)) {
          photo_type.push(PHOTO_TYPE.CAR_INTERIOR);
        }
      }

      photo_type.push(
        verifyStatement(
          dealerPhotos,
          PHOTO_TYPE.DEALER,
          get(inventory, DATA_PATH.STOCK_PHOTO_PROVIDER, defaults.defaultNoImageType || PHOTO_TYPE.NO_IMAGE)
        )
      );
      dealer_rating.push(get(inventory, DATA_PATH.DEALER_RATING));
      five_star_dealer.push(verifyStatement(get(inventory, DATA_PATH.FIVE_STAR_DEALER, []).length, POSITIVE, NEGATIVE));
      const dealerPrice = get(inventory, DATA_PATH.DEALER_PRICE);
      const showDealerPrice = useDealerPrice && isDealerPriceAvailable(inventory);
      days_listed.push(get(inventory, DATA_PATH.DAYS_ON_EDMUNDS, DEFAULT_VALUE));
      price.push(showDealerPrice ? dealerPrice : get(inventory, DATA_PATH.PRICE, NEGATIVE));
      below_market.push(get(inventory, DATA_PATH.BELOW_MARKET, DEFAULT_VALUE));
      deal_indicator.push(get(inventory, DATA_PATH.DEAL_INDICATOR, DEFAULT_VALUE));
      vehicle_history.push(
        getCorrectStatus(
          get(inventory, DATA_PATH.FREE_HISTORY_REPORT),
          get(inventory, DATA_PATH.HISTORY_PROVIDER, DEFAULT_VALUE)
        )
      );
      recommended_offer.push(get(inventory, DATA_PATH.RECOMMENDED_OFFER, DEFAULT_VALUE));
      miles_away.push(getValueToFixed(get(inventory, DATA_PATH.DEALER_DISTANCE), DEFAULT_VALUE));
    }
  });

  const sortByAlgorithm = get(attributes, DATA_PATH.SORT_BY_ALGORITHM, DEFAULT_VALUE);

  return {
    match_type_list: match_type_list.join(),
    cpo_results: `${cpo_results}`,
    new_results: `${new_results}`,
    used_results: `${used_results}`,
    match_total: `${match_total}`,
    match_results: `${match_results}`,
    cpo: verifyStatement(cpo_results, MATCH_RESULT.CPO, MATCH_RESULT.NOT_CPO),
    inv_treatment_list: getInvTreatmentList(inventories),
    sort_algo_yes_no: verifyStatement(sortByAlgorithm, POSITIVE, NEGATIVE),
    sort_algo_name: sortByAlgorithm,
    price: price.join(),
    ...(useTotalPriceChange ? { total_price_change: total_price_change.join() } : { price_drop: price_drop.join() }),
    photo_type: photo_type.join(),
    days_listed: days_listed.join(),
    below_market: below_market.join(),
    deal_indicator: deal_indicator.join(),
    five_star_dealer: five_star_dealer.join(),
    vehicle_history: vehicle_history.join(),
    recommended_offer: recommended_offer.join(),
    dealer_rating: dealer_rating.join(),
    ...(isSrp ? { miles_away: miles_away.join() } : {}),
  };
}

/**
 * Fires inventories listing tracking.
 * @param {object[]} inventory
 * @param {object} [defaults]
 * @param {object} [attributes]
 * @param {object} [additionalParams]
 * @param {string} creativeId
 * @param {string|number} [radius]
 * @param {number} [totalCount]
 * @param {boolean} [isSrp]
 * @param {object} [photo360]
 * @param {boolean} [isNational]
 * @param {boolean} useDealerPrice
 */
function fireInventoryListView({
  detail: {
    inventory,
    creativeId,
    radius,
    defaults,
    totalCount,
    attributes,
    isSrp,
    photo360,
    isNational,
    useDealerPrice,
    ...additionalParams
  },
}) {
  fireListTracking(inventory, creativeId, {
    ...getInventoryListTrackingData({
      inventories: inventory,
      defaults,
      totalCount,
      attributes,
      isSrp,
      photo360,
      useTotalPriceChange: !!creativeId && creativeId.includes(VIN_OVERVIEW_ID),
      isNational,
      radius,
      useDealerPrice,
    }),
    radius,
    ...additionalParams,
  });
}

/**
 * Fires price promise price display tracking pixel.
 * @param {object} target
 * @param {number} price
 */
export function firePricePromisePriceDisplayTracking({ target, detail: { price } }) {
  const eventData = {
    action_name: TrackingConstant.ACTION_SHOW_CONTENT,
    subaction_name: TrackingConstant.SHOW_DEALER_PRICE,
    action_cause: 'page_load',
    action_category: 'system',
    creative_id: getCreativeId(target),
    value: price,
  };

  EventToolbox.fireTrackAction({
    event_type: 'action_completed',
    event_data: eventData,
  });
}

/**
 * Fires drawer open.
 * @param {string} action
 * @param {string} subaction
 * @param {string} creativeId
 * @param {string} [eventType=action_completed]
 * @param {boolean} [isLink=false]
 * @param {string|null} [value=null]
 */
export function fireDrawerOpen(
  action,
  subaction,
  creativeId,
  eventType = 'action_completed',
  isLink = false,
  value = null
) {
  const eventData = {
    action_name: action,
    action_cause: isLink ? 'link_click' : 'button_click',
    action_category: 'user',
    subaction_name: subaction,
    creative_id: creativeId,
    value,
  };

  EventToolbox.fireTrackAction({
    event_type: eventType,
    event_data: eventData,
  });
}

export function fireCpoPanelView({ detail: { creativeId } }) {
  const eventData = {
    action_name: TrackingConstant.ACTION_SHOW_CONTENT,
    subaction_name: TrackingConstant.SHOW_CPO_PANEL,
    action_cause: 'page_load',
    action_category: 'system',
    creative_id: creativeId,
  };

  EventToolbox.fireTrackAction({
    event_type: 'action_completed',
    event_data: eventData,
  });
}

export function fireCpoLabelView({ detail: { creativeId } }) {
  const eventData = {
    action_name: TrackingConstant.ACTION_SHOW_CONTENT,
    subaction_name: TrackingConstant.SHOW_CPO_LABEL,
    action_cause: 'page_load',
    action_category: 'system',
    creative_id: creativeId,
    uniq: randomInt(),
  };

  EventToolbox.fireTrackAction({
    event_type: 'action_completed',
    event_data: eventData,
  });
}

export function fireWidgetOpen({
  pageName,
  eventType = TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
  creativeId = TrackingConstant.CREATIVE_ID_VDP_INSTANT_CACHE_OFFER,
  actionCause = TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
  actionName = TrackingConstant.ACTION_WIDGET_VIEW,
  synpartner = TrackingConstant.SYNPARTNER,
}) {
  const eventData = {
    creative_id: creativeId,
    action_cause: actionCause,
    action_name: actionName,
    action_category: 'user',
  };

  EventToolbox.fireTrackAction({
    page_name: pageName,
    event_type: eventType,
    event_data: eventData,
    synpartner,
  });
}

export function fireDealCheckView({ detail: { creativeId } }) {
  const pageHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
    event_data: {
      action_name: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
      action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
      total_page_height: pageHeight,
      creative_id: creativeId,
    },
  });
}

export function fireOpenTed({ detail: { isPurchase } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
      subaction_name: isPurchase ? TrackingConstant.VIEW_LOAN_DETAILS : TrackingConstant.VIEW_LEASE_DETAILS,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      creative_id: 'digital-retail-entry-module',
      value: 'Get Started',
    },
  });
}

export function fireBuyOnlineSrpCardBtnClick({ detail: { partnerId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_CONTENT,
      subaction_name: TrackingConstant.BUY_ONLINE_CLICKOUT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      creative_id: 'bol-srp-card',
    },
    partner_id: partnerId,
  });
}

export function fireVdpPricinBoxBtnClick(
  { detail: { isMobile, value, partnerId, type = 'used' } } = { detail: { isMobile: false } }
) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_CONTENT,
      subaction_name: TrackingConstant.BUY_ONLINE_CLICKOUT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: isMobile
        ? `${type}-buy_online_cta_inline-${type}_model_car_inventory_vin_detail`
        : `${type}-buy_online_cta-${type}_model_car_inventory_vin_detail`,
      value,
    },
    partner_id: partnerId,
  });
}

export function fireVdpEmbeddedBoxBtnClick({ detail: { value, type, partnerId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_CONTENT,
      subaction_name: TrackingConstant.BUY_ONLINE_CLICKOUT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: `${type}-buy_online_cta_embedded-${type}_model_car_inventory_vin_detail`,
      value,
    },
    partner_id: partnerId,
  });
}

export function fireToggleDeliveryFees({ detail: { isIncluded } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      subaction_name: TrackingConstant.FILTER_SEARCH_RESULT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: 'edm-entry-facets-buying-experience',
      value: `IncludeDeliveryFees~${isIncluded ? 'true' : 'false'}~Include Delivery Fees in Prices`,
    },
  });
}

export function fireOpenFinancingAppTracking({ detail: { creativeId, value } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      subaction_name: TrackingConstant.SUBACTION_OPEN_FINANCING_APP,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: creativeId,
      value,
    },
  });
}

export const InventoryEngagementHandler = {
  init({ getState }) {
    EventToolbox.on(ACTIONS.INVENTORY_LIST_VIEW, event => fireInventoryListView(event, getState));
    EventToolbox.on(ACTIONS.PRICE_PROMISE_PRICE_DISPLAY, firePricePromisePriceDisplayTracking);
    EventToolbox.on(ACTIONS.CPO_PANEL_VIEW, fireCpoPanelView);
    EventToolbox.on(ACTIONS.CPO_LABEL_VIEW, fireCpoLabelView);
    EventToolbox.on(ACTIONS.DEAL_CHECK_VIEW, fireDealCheckView);
    EventToolbox.on(INVENTORY_PAGE_CUSTOM_EVENTS.DIGITAL_RETAIL_ENTRY_OPEN_TED, fireOpenTed);
    EventToolbox.on(ACTIONS.BUY_ONLINE_SRP_CARD_BTN_CLICK, fireBuyOnlineSrpCardBtnClick);
    EventToolbox.on(ACTIONS.PRICING_BOX_BTN_CLICK, fireVdpPricinBoxBtnClick);
    EventToolbox.on(ACTIONS.EMBEDDED_BOX_BTN_CLICK, fireVdpEmbeddedBoxBtnClick);
    EventToolbox.on(ACTIONS.TOGGLE_DELIVERY_FEES, fireToggleDeliveryFees);
    EventToolbox.on(ACTIONS.OPEN_FINANCING_APP, fireOpenFinancingAppTracking);
  },
};
