import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Range as BaseRange } from 'site-modules/shared/components/range/range';

import './range.scss';

export function Range(props) {
  const { rangeGroupClassName, ...rest } = props;

  return (
    <BaseRange
      {...rest}
      withOptionsScale={false}
      className="inventory-range"
      rangeGroupClassName={classnames('range-group', rangeGroupClassName)}
      mainColor="var(--range-inventory-main-color)"
      disabledColor="var(--range-inventory-disabled-color)"
    />
  );
}

Range.propTypes = {
  rangeGroupClassName: PropTypes.string,
};

Range.defaultProps = {
  rangeGroupClassName: '',
};
