import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { facetShape, SELECT_FILTER_CATEGORY_LABEL } from 'site-modules/shared/constants/inventory/filters-constants';
import { MODEL } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { get } from 'lodash';
import { getCategoryGroupedOptions } from 'site-modules/shared/utils/inventory/get-category-grouped-options';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';

import { UsurpSelectFilter } from './usurp-select-filter';

function UsurpSelectMakeModelFilterUI({ facet, facetType, make, isSHOP1857Enabled, ...props }) {
  let options = [];

  if (facetType === MODEL) {
    const { groups: groupedByMakeValues } = facet;
    options = get(groupedByMakeValues, make, []);
  } else {
    options = facet.values;
  }

  const optionsGrouped = useMemo(
    () => getCategoryGroupedOptions(options, SELECT_FILTER_CATEGORY_LABEL[facetType], isSHOP1857Enabled),
    [facetType, isSHOP1857Enabled, options]
  );

  return <UsurpSelectFilter {...props} facet={{ ...facet, values: options }} optionsGrouped={optionsGrouped} />;
}

UsurpSelectMakeModelFilterUI.propTypes = {
  make: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  facet: facetShape.isRequired,
  facetType: PropTypes.string.isRequired,
  isSHOP1857Enabled: PropTypes.bool,
};

UsurpSelectMakeModelFilterUI.defaultProps = {
  make: null,
  isSHOP1857Enabled: false,
};

const mapStateToProps = state => ({
  isSHOP1857Enabled: ExperimentUtil.getForcedOrAssignedRecipeName({
    state,
    campaignName: 'shop-1857-model-family',
    defaultVal: 'ctrl',
  }).includes('chal'),
});

export const UsurpSelectMakeModelFilter = connect(mapStateToProps)(UsurpSelectMakeModelFilterUI);
