import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PRICE_PAYMENT_GROUP } from 'site-modules/shared/constants/inventory/filters-constants';
import { useInventoryDependency } from 'site-modules/shared/components/inventory/inventory-dependency/use-inventory-dependency';

export const UsurpHistogram = memo(props => {
  const { facet, ...rest } = props;
  const {
    filters: {
      histogram: { component: HistogramComponent },
    },
  } = useInventoryDependency();

  return facet.config.group === PRICE_PAYMENT_GROUP ? <HistogramComponent facetType={facet.type} {...rest} /> : null;
});

UsurpHistogram.displayName = 'UsurpHistogram';
UsurpHistogram.propTypes = {
  facet: PropTypes.shape({
    type: PropTypes.string,
  }),
};
