import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './badge.scss';

export function Badge(props) {
  const { children, className } = props;

  return (
    <span className={classnames('inventory-badge bg-green-50 size-10 font-weight-bold text-white', className)}>
      {children}
    </span>
  );
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Badge.defaultProps = {
  className: '',
};
