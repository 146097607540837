import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import { InventoryEntities } from 'client/data/models/inventory';
import { getFacetTrackingParams, getSelectedFacetTrackingParams } from 'site-modules/shared/utils/inventory/tracking';
import { buildFilterPixelValue } from 'site-modules/shared/utils/inventory-utils/build-filter-pixel-value';
import { DefaultCheckbox } from 'site-modules/shared/components/inventory/usurp-filter/usurp-filter-checkbox/checkbox-facet';

import './editorial-rating-item.scss';

const SHIELD_COLOR_MAP = {
  Great: { svg: 'great', icon: 'text-green-60' },
  Good: { svg: 'good', icon: 'text-turquoise-20' },
};

export function EditorialRatingItem({ facet, onUpdate }) {
  const { name, count, selected } = facet;
  const handleClick = useCallback(
    e => {
      e.preventDefault();
      onUpdate(facet, !selected);
    },
    [facet, onUpdate, selected]
  );

  const dataTrackingValue = buildFilterPixelValue('ratingType', name, name);
  const trackingData = {
    ...(selected ? getSelectedFacetTrackingParams(dataTrackingValue) : getFacetTrackingParams(dataTrackingValue)),
    'data-tracking-parent': 'edm-entry-facets-edmunds_editorial_rating',
  };

  return (
    <Label
      className={classnames('pos-r checkbox-facet editorial-rating mb-0', {
        'disabled no-pointer': !count && !selected,
        selected,
      })}
      {...trackingData}
    >
      <svg
        width={97}
        height={102}
        viewBox="0 0 97 102"
        className={classnames('shield', { base: !selected, [SHIELD_COLOR_MAP[name].svg]: selected })}
      >
        <path d="M4.56687 75.3543C2.35013 73.989 1 71.5718 1 68.9683L1.00001 16.8926C1.00001 13.3318 3.5037 10.2619 6.99171 9.54578L46.3884 1.4576C47.7816 1.17156 49.2184 1.17156 50.6116 1.4576L90.0083 9.54579C93.4963 10.2619 96 13.3318 96 16.8926L96 68.9683C96 71.5718 94.6499 73.989 92.4331 75.3543L52.4331 99.9905C50.0214 101.476 46.9786 101.476 44.5669 99.9905L4.56687 75.3543Z" />
      </svg>

      <div className="pos-a center-x d-flex flex-column align-items-center" style={{ top: '1.25rem' }}>
        <Input
          onChange={handleClick}
          type="checkbox"
          className="input m-0 no-focus"
          checked={!!selected}
          disabled={!count && !selected}
        />
        <DefaultCheckbox
          selected={selected}
          selectedClassName={classnames('icon-checkbox-checked3', { [SHIELD_COLOR_MAP[name].icon]: selected })}
          deselectedClassName="icon-checkbox-unchecked3 text-cool-gray-50"
        />
        <span
          title={name}
          className={classnames(
            'shield-name-wrapper d-flex align-items-center text-center text-cool-gray-30 size-14 font-weight-medium',
            { 'text-cool-gray-10': selected }
          )}
        >
          {name}
        </span>
      </div>
    </Label>
  );
}

EditorialRatingItem.propTypes = {
  facet: InventoryEntities.FacetValue.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
