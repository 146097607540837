import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { facetShape } from 'site-modules/shared/constants/inventory/filters-constants';
import { TrackingConstant } from 'client/tracking/constant';
import { EventToolbox } from 'client/utils/event-toolbox';
import { buildFilterPixelValue } from 'site-modules/shared/utils/inventory-utils/build-filter-pixel-value';
import { Select } from 'site-modules/shared/components/inventory/select/select';
import { MODEL } from 'site-modules/shared/constants/allowed-inventory-request-params';

export const KEY_CODES = {
  space: 32,
  enter: 13,
};

export function getSelectedFacet(values) {
  return values.find(({ selected }) => selected) || {};
}

export class UsurpSelectFilter extends Component {
  static propTypes = {
    facet: facetShape.isRequired,
    optionsGrouped: PropTypes.shape({}),
    onUpdate: PropTypes.func.isRequired,
    currentValue: PropTypes.string,
  };

  static defaultProps = {
    optionsGrouped: {},
    currentValue: '',
  };

  handleSelect = selectedOption => {
    const { facet, onUpdate } = this.props;
    const { type, config } = facet;
    const { creativeId } = config;
    const option = selectedOption || { type };

    if (selectedOption) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.VIEW_SEARCH_RESULT,
          subaction_name: TrackingConstant.FILTER_SEARCH_RESULT,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.SELECT_CHANGE,
          creative_id: creativeId,
          value: buildFilterPixelValue(type, selectedOption.value, selectedOption.name),
        },
      });
    }

    // for multi select, if selectedOption is true then we should always pass isChecked=true,
    // selectedOption=false means that user selected 'Any' value
    onUpdate(option, !!selectedOption);
  };

  fireModelTracking = () => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_START,
      event_data: {
        action_name: TrackingConstant.ACTION_MMY_SELECT,
        subaction_name: TrackingConstant.MAKE_SELECT_OPEN,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.SELECT_CHANGE,
        creative_id: 'edm-entry-facets-make_and_model',
      },
    });
  };

  handleMouseDown = () => {
    this.fireModelTracking();
  };

  handleKeyDown = event => {
    if ([KEY_CODES.space, KEY_CODES.enter].includes(event.keyCode)) {
      this.fireModelTracking();
    }
  };

  render() {
    const { facet, optionsGrouped, currentValue } = this.props;
    const { values, config, type } = facet;
    const { toggle, label } = config;

    const { value } = getSelectedFacet(values);
    const events = { onMouseDown: this.handleMouseDown, onKeyDown: this.handleKeyDown };

    return (
      <Select
        options={values}
        optionsGrouped={optionsGrouped}
        value={currentValue}
        name={type}
        toggle={toggle}
        isFirstOptionDisabled={false}
        valueKey={value ? 'value' : 'name'}
        labelKey="name"
        onChange={this.handleSelect}
        {...(facet.type === MODEL ? events : {})}
        id={`usurp-${type}-select`}
        labelText={label}
        isLabelHidden
      />
    );
  }
}
