import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input as ReactstrapInput } from 'reactstrap';
import classnames from 'classnames';

import './input.scss';

export const Input = forwardRef((props, ref) => {
  const { className, startAdornment, inputClassName, bsSize, ...rest } = props;
  const { disabled } = rest;
  return (
    <span
      className={classnames('inventory-input d-inline-flex align-items-center w-100 form-control', bsSize, className, {
        disabled,
      })}
    >
      {startAdornment && <span className="start-adornment">{startAdornment}</span>}
      <ReactstrapInput className={inputClassName} innerRef={ref} {...rest} />
    </span>
  );
});

Input.propTypes = {
  className: PropTypes.string,
  startAdornment: PropTypes.node,
  bsSize: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  className: '',
  startAdornment: null,
  bsSize: undefined,
  inputClassName: 'text-center',
  disabled: false,
};
