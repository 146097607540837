import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './toggle-button-group.scss';

export function ToggleButtonGroup({
  children,
  activeButtonAdornment,
  onChange,
  value,
  activeClassName,
  inactiveClassName,
  className,
  style,
  role,
  ariaLabel,
}) {
  const handleChange = useCallback(
    (event, buttonValue) => {
      if (!onChange || buttonValue === undefined) {
        return;
      }
      onChange(event, buttonValue);
    },
    [onChange]
  );

  return (
    <div
      className={classnames('toggle-button-group d-flex w-100', className)}
      role={role}
      style={style}
      aria-label={ariaLabel}
    >
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return child;
        }
        const extraProps = { activeButtonAdornment, activeClassName, inactiveClassName };
        const isActive = value === child.props.value;

        extraProps.onClick = event => {
          if (!event.defaultPrevented && !isActive) {
            handleChange(event, child.props.value);
          }
        };

        extraProps.isActive = isActive;

        return React.cloneElement(child, extraProps);
      })}
    </div>
  );
}

ToggleButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  activeButtonAdornment: PropTypes.element,
  activeClassName: PropTypes.string,
  inactiveClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  role: PropTypes.string,
  ariaLabel: PropTypes.string,
};

ToggleButtonGroup.defaultProps = {
  activeClassName: null,
  inactiveClassName: null,
  activeButtonAdornment: undefined,
  className: null,
  style: null,
  role: 'group',
  ariaLabel: undefined,
};
