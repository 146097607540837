import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { combineFacetValues } from 'site-modules/shared/utils/inventory/search-filter';
import { EXTERIOR_COLOR, INTERIOR_COLOR, TRIM } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { FacetsConfigShape, facetShape } from 'site-modules/shared/constants/inventory/filters-constants';
import { ShowMore } from 'site-modules/shared/components/inventory/show-more/show-more';
import { CheckboxFacet } from './checkbox-facet';

import './usurp-filter-checkbox.scss';

const VISIBLE_VALUES_NUMBER = 6;
const COLORS_VISIBLE_VALUES_NUMBER = 3;

export class UsurpFilterCheckbox extends Component {
  static propTypes = {
    facet: facetShape,
    onUpdate: PropTypes.func.isRequired,
    facetsConfig: FacetsConfigShape.isRequired,
    encodePixelValue: PropTypes.bool,
  };

  static defaultProps = {
    facet: {},
    encodePixelValue: false,
  };

  renderCheckbox = ({ facet, ref }) => {
    const config = facet.config || this.props.facet.config;
    const type = facet.type || this.props.facet.type;
    const { hideCount, description, countClasses } = config;
    const { value, id, name, selected, count } = facet;
    const { onUpdate, encodePixelValue, facetsConfig } = this.props;
    let key = facet.type || value || id || name;
    key = type === TRIM ? `${value}-${count}` : key;

    return (
      <li
        key={key}
        className={classnames('usurp-filter-checkbox-item p-0_5', {
          'bg-cool-gray-80': !!selected,
          selected: !!selected,
        })}
      >
        <CheckboxFacet
          onUpdate={onUpdate}
          facet={facet}
          type={type}
          hideCount={hideCount}
          description={description}
          encodePixelValue={encodePixelValue}
          facetsConfig={facetsConfig}
          countClasses={countClasses}
          innerRef={ref}
        />
      </li>
    );
  };

  render() {
    const { facet } = this.props;
    const { config = {}, type } = facet;
    const { combineValues, maxOptionsBeforeCollapse, creativeId, title, withoutCollapse, noWidgetView } = config;

    const isColorFacet = [EXTERIOR_COLOR, INTERIOR_COLOR].includes(type);

    const { values = [] } = facet;
    let firstPart = [];
    let secondPart = [];

    const optionsBeforeCollapse =
      maxOptionsBeforeCollapse || (isColorFacet ? COLORS_VISIBLE_VALUES_NUMBER : VISIBLE_VALUES_NUMBER);

    if (combineValues) {
      firstPart = combineFacetValues(values);
    } else if (!withoutCollapse) {
      firstPart = values.slice(0, optionsBeforeCollapse);
      secondPart = values.slice(firstPart.length);
    } else {
      firstPart = values;
    }

    const hasCollapse = !!secondPart.length;

    return (
      <div
        className="usurp-filter-checkbox"
        data-tracking-parent={creativeId}
        {...(noWidgetView ? { 'data-no-widget-view': true } : {})}
      >
        <ul className="list-unstyled mb-0">
          {firstPart.map((value, ind) =>
            this.renderCheckbox({
              facet: value,
              isColorFacet,
              isLast: hasCollapse ? false : ind === firstPart.length - 1,
            })
          )}
        </ul>
        {hasCollapse && (
          <ShowMore
            expandedAriaLabel={`Hide ${title}`}
            collapsedAriaLabel={`Show ${secondPart.length} more ${title}`}
            expandedText="Hide"
            collapsedText={`Show ${secondPart.length} more`}
          >
            {({ ref }) => (
              <ul className="list-unstyled mb-0">
                {secondPart.map((value, ind) =>
                  this.renderCheckbox({
                    facet: value,
                    isColorFacet,
                    isLast: ind === secondPart - 1,
                    ref: ind === 0 ? ref : null,
                  })
                )}
              </ul>
            )}
          </ShowMore>
        )}
      </div>
    );
  }
}
