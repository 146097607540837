import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { getMakeName, getModelName } from 'site-modules/shared/utils/inventory/multi-make-model';

import './search-item.scss';

export function SearchItem({ facets, onRemove, make, model }) {
  const makeName = getMakeName({ facets, make });
  const modelName = getModelName({ facets, makeName, model });

  function handleRemoveAnotherClick() {
    onRemove({ make, makeName, model, modelName });
  }

  return (
    <li
      key={`${make} ${model}`}
      className="search-item d-inline-block text-nowrap px-0_75 mr-0_5 mb-0_5 bg-blue-90 overlay-white-40"
    >
      <Button
        color="link"
        className="text-primary-darker p-0 medium text-decoration-none text-transform-none d-flex align-items-center"
        onClick={handleRemoveAnotherClick}
        aria-label={`Remove ${makeName}${modelName ? ` ${modelName}` : ''} from search`}
      >
        <span className="font-weight-medium mr-0_5 search-item-label text-left">
          {makeName}
          {modelName && ` ${modelName}`}
        </span>
        <span className="icon-cancel-circle2" aria-hidden />
      </Button>
    </li>
  );
}

SearchItem.propTypes = {
  onRemove: PropTypes.func.isRequired,
  facets: PropTypes.arrayOf(PropTypes.shape({})),
  make: PropTypes.string,
  model: PropTypes.string,
};

SearchItem.defaultProps = {
  facets: [],
  make: '',
  model: '',
};
