import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StyledSelect, StyledSelectPropTypes } from 'site-modules/shared/components/styled-select/styled-select';
import { SelectDefaultProps } from 'site-modules/shared/components/select/select';

import './select.scss';

export function Select(props) {
  const { className, ...rest } = props;
  return (
    <StyledSelect
      {...rest}
      className={classnames('inventory-select', className)}
      wrapperClassName="inventory-select-wrapper"
    />
  );
}

Select.propTypes = {
  ...StyledSelectPropTypes,
  className: PropTypes.string,
};

Select.defaultProps = {
  ...SelectDefaultProps,
  className: '',
};

Select.displayName = 'Select';
