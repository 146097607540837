import PropTypes from 'prop-types';
import { InventoryEntities } from 'client/data/models/inventory';
import { MAKE, MODEL } from 'site-modules/shared/constants/allowed-inventory-request-params';

export const LOCATION_GROUP = 'LOCATION_GROUP';
export const MM_GROUP = 'MM_GROUP';
export const YEAR_GROUP = 'YEAR_GROUP';
export const CONDITION_GROUP = 'CONDITION_GROUP';
export const PRICE_PAYMENT_GROUP = 'PRICE_PAYMENT_GROUP';
export const RATING_GROUP = 'RATING_GROUP';
export const MILEAGE_GROUP = 'MILEAGE_GROUP';
export const HISTORY_GROUP = 'HISTORY_GROUP';
export const MODEL_TYPE_GROUP = 'MODEL_TYPE_GROUP';
export const TRIM_GROUP = 'TRIM_GROUP';
export const MPG_GROUP = 'MPG_GROUP';
export const EXT_COLOR_GROUP = 'EXT_COLOR_GROUP';
export const INT_COLOR_GROUP = 'INT_COLOR_GROUP';
export const ELECTRIC_DETAILS_GROUP = 'ELECTRIC_DETAILS_GROUP';
export const ENGINE_GROUP = 'ENGINE_GROUP';
export const FEATURES_GROUP = 'FEATURES_GROUP';
export const OPTIONS_GROUP = 'OPTIONS_GROUP';
export const VEHICLE_LISTING_DETAILS_GROUP = 'VEHICLE_LISTING_DETAILS_GROUP';
export const TRUCK_DETAILS_GROUP = 'TRUCK_DETAILS_GROUP';
export const CREDIT_PROVIDER_GROUP = 'creditProvider';
export const TOTAL_SEATING_GROUP = 'TOTAL_SEATING_GROUP';
export const EDITORIAL_RATING_GROUP = 'EDITORIAL_RATING_GROUP';

export const PRICE_PAYMENT_TABS_SUBGROUP = 'PRICE_PAYMENT_TABS_SUBGROUP';
export const LISTING_DETAILS = 'listingDetails';

export const facetShape = PropTypes.shape({
  type: PropTypes.string,
  config: PropTypes.shape({
    hideCount: PropTypes.bool,
    isAccentCheckbox: PropTypes.bool,
    description: PropTypes.string,
  }),
  values: PropTypes.arrayOf(InventoryEntities.FacetValue),
  groups: PropTypes.objectOf(PropTypes.arrayOf(InventoryEntities.FacetValue)),
});

export const SELECT_FILTER_CATEGORY_LABEL = {
  [MAKE]: 'Makes',
  [MODEL]: 'Models',
};

export const FacetsConfigShape = PropTypes.shape({
  title: PropTypes.string,
  tooltip: PropTypes.shape({ id: PropTypes.string, text: PropTypes.string }),
  subtitle: PropTypes.shape({
    subtitleText: PropTypes.string,
    subtitleClassNames: PropTypes.string,
    subtitleTooltip: PropTypes.shape({ id: PropTypes.string, text: PropTypes.string }),
  }),
  label: PropTypes.string,
  labelType: PropTypes.string,
  labelPostfix: PropTypes.string,
  control: PropTypes.string,
  group: PropTypes.string,
  indexInsideGroup: PropTypes.number,
  toggle: PropTypes.string,
  creativeId: PropTypes.string,
  shortName: PropTypes.string,
  allowEqualValues: PropTypes.bool,
  step: PropTypes.number,
  filterClassName: PropTypes.string,
  maxOptionsBeforeCollapse: PropTypes.number,
  excludeZeroCount: PropTypes.bool,
  getDisplayName: PropTypes.func,
  getPixelDisplayName: PropTypes.func,
  appendZeroOption: PropTypes.bool,
  subgroup: PropTypes.string,
  filterOnSelect: PropTypes.shape({ type: PropTypes.string, value: PropTypes.string }),
  dataTrackingId: PropTypes.string,
  disclaimer: PropTypes.string,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  hideCount: PropTypes.bool,
  alternativeTitle: PropTypes.string,
  copy: PropTypes.string,
});

export const FacetsGroupsConfigShape = PropTypes.objectOf(
  PropTypes.shape({
    title: PropTypes.string,
    order: PropTypes.number,
    creativeId: PropTypes.string,
    shortName: PropTypes.string,
    withInnerDividers: PropTypes.bool,
  })
);

export const FacetsConfigMapShape = PropTypes.shape({
  facetsConfig: FacetsConfigShape,
  facetsGroupsConfig: FacetsGroupsConfigShape,
});
