import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const BAR_BORDER_RADIUS = 2;
const RADIUS_BEZIER_HANDLE_DISTANCE = 1;
const BAR_TOP_INDENT = 12;

export const Bar = memo(props => {
  const { index, barColor, gutter, barWidth, maxDataValue, value, viewBox } = props;

  const barGutter = !index || gutter;
  const barWidthWithGutter = barWidth + barGutter;
  const barStart = index * barWidthWithGutter;
  const barEnd = barStart + barWidth - BAR_BORDER_RADIUS;

  const barVerticalStartIndent = useMemo(() => {
    const longestBarHeight = viewBox.height - BAR_TOP_INDENT;

    const percent = value / maxDataValue;
    const barHeight = longestBarHeight * percent;

    return !barHeight ? viewBox.height + BAR_BORDER_RADIUS : viewBox.height - barHeight;
  }, [maxDataValue, value, viewBox.height]);

  return (
    <path
      d={`M${barStart} ${barVerticalStartIndent}C${barStart} ${barVerticalStartIndent -
        RADIUS_BEZIER_HANDLE_DISTANCE} ${barStart + RADIUS_BEZIER_HANDLE_DISTANCE} ${barVerticalStartIndent -
        BAR_BORDER_RADIUS} ${barStart + BAR_BORDER_RADIUS} ${barVerticalStartIndent -
        BAR_BORDER_RADIUS}H${barEnd}C${barEnd + RADIUS_BEZIER_HANDLE_DISTANCE} ${barVerticalStartIndent -
        BAR_BORDER_RADIUS} ${barStart + barWidth} ${barVerticalStartIndent - RADIUS_BEZIER_HANDLE_DISTANCE} ${barStart +
        barWidth} ${barVerticalStartIndent}V${viewBox.height}H${barStart}V${barVerticalStartIndent}Z`}
      fill={barColor}
    />
  );
});

Bar.displayName = 'Bar';
Bar.propTypes = {
  index: PropTypes.number.isRequired,
  barColor: PropTypes.string.isRequired,
  gutter: PropTypes.number.isRequired,
  barWidth: PropTypes.number.isRequired,
  maxDataValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  viewBox: PropTypes.shape({ height: PropTypes.number, width: PropTypes.number }).isRequired,
};
