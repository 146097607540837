import React from 'react';
import PropTypes from 'prop-types';
import { facetShape } from 'site-modules/shared/constants/inventory/filters-constants';
import { EditorialRatingItem } from 'site-modules/shared/components/inventory/usurp-editorial-rating-filter/editorial-rating-item';

import './usurp-editorial-rating-filter.scss';

export function UsurpEditorialRatingFilter({ onUpdate, facet }) {
  const { values } = facet;

  return (
    <div className="usurp-filter-checkbox">
      <ul className="list-unstyled shield-list d-flex flex-lg-column flex-xl-row align-items-center justify-content-center mb-0">
        {values.map(value => (
          <li key={value.name}>
            <EditorialRatingItem facet={value} onUpdate={onUpdate} />
          </li>
        ))}
      </ul>
    </div>
  );
}

UsurpEditorialRatingFilter.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  facet: facetShape.isRequired,
};
