import { groupBy, mapValues } from 'lodash';

export function getCategoryGroupedOptions(values, type, isSHOP1857Enabled) {
  const groups = groupBy(values, 'category');
  let optionsGrouped = {};

  const { popular, other } = mapValues(groups, groupValues =>
    groupValues.map(groupValue => ({
      ...groupValue,
      name: groupValue.isChild ? `-- ${groupValue.name}` : groupValue.name,
    }))
  );
  // Hack to return popular models first
  optionsGrouped = {
    ...(popular ? { [`Popular ${type}`]: popular } : {}),
    ...(other ? { [`${isSHOP1857Enabled ? 'All' : 'Other'} ${type}`]: other } : {}),
  };

  return optionsGrouped;
}
