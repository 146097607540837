import React, { useCallback, useId } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';

import './collapsible-filters-group.scss';

export const CollapsibleFiltersGroup = ({
  children,
  className,
  hideCollapseSeparator,
  textClosed,
  textOpen,
  trackingId,
  collapseTrackingId,
  trackingValue,
  isOpenOnLoad,
  btnContainerClassName,
  collapseClassName,
  accessibilityCallback,
  creativeId,
  titleTag: TitleTag,
}) => {
  const id = useId();
  const collapseControlTitleId = `collapsible-filters-group-${id}`;

  const renderCollapseControl = useCallback(
    ({ isOpen, onToggle }) => (
      <Col className="pb-1">
        <TitleTag className="d-flex align-items-center justify-content-between mb-0">
          <Button
            className="content-collapse-button pos-a w-100 top-0 right-0 left-0 bottom-0 rounded-6"
            color="link"
            onClick={onToggle}
            data-no-refresh
            aria-expanded={isOpen}
            aria-labelledby={collapseControlTitleId}
            data-tracking-ignore-value
          />
          <span
            id={collapseControlTitleId}
            className="size-16 text-cool-gray-10 font-weight-bold text-decoration-none d-flex w-100 align-items-center"
          >
            {isOpen ? textOpen : textClosed}
          </span>
          <span
            className={classnames(
              'collapsible-filters-arrow text-blue-20 small',
              isOpen ? 'icon-arrow-up3' : 'icon-arrow-down3'
            )}
            aria-hidden
          />
        </TitleTag>
      </Col>
    ),
    [collapseControlTitleId, textClosed, textOpen]
  );

  return (
    <div
      className={classnames('collapsible-filters-group pt-1', { 'hide-separator': hideCollapseSeparator }, className)}
      data-tracking-parent="srp-new-drawer-facets"
    >
      <ContentCollapse
        textClosed={textClosed}
        textOpen={textOpen}
        justify="justify-content-start"
        btnContainerClasses={classnames('px-1 pt-0 pos-r', btnContainerClassName)}
        isEclipseFade={false}
        trackingId={trackingId}
        collapseTrackingId={collapseTrackingId}
        trackingValue={trackingValue}
        isOpenOnLoad={isOpenOnLoad}
        accessibilityCallback={accessibilityCallback}
        renderCollapseControl={renderCollapseControl}
      >
        {children && (
          <div className={collapseClassName} {...(creativeId ? { 'data-tracking-parent': creativeId } : {})}>
            {children}
          </div>
        )}
      </ContentCollapse>
    </div>
  );
};

CollapsibleFiltersGroup.propTypes = {
  children: PropTypes.node,
  textClosed: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  trackingId: PropTypes.string,
  collapseTrackingId: PropTypes.string,
  trackingValue: PropTypes.string,
  className: PropTypes.string,
  btnContainerClassName: PropTypes.string,
  collapseClassName: PropTypes.string,
  hideCollapseSeparator: PropTypes.bool,
  isOpenOnLoad: PropTypes.bool,
  accessibilityCallback: PropTypes.func,
  creativeId: PropTypes.string,
  titleTag: PropTypes.string,
};

CollapsibleFiltersGroup.defaultProps = {
  children: undefined,
  className: undefined,
  textClosed: undefined,
  textOpen: undefined,
  trackingId: undefined,
  collapseTrackingId: undefined,
  trackingValue: undefined,
  hideCollapseSeparator: undefined,
  isOpenOnLoad: undefined,
  btnContainerClassName: undefined,
  collapseClassName: undefined,
  accessibilityCallback: undefined,
  creativeId: null,
  titleTag: 'h3',
};
